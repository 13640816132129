<template>
<div>
    <div class="d-flex justify-start align-center" style="height:60px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
      <v-icon color="#d31145" @click="$router.push('/master')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Produk</span>
    </div>
    
    <v-divider></v-divider>

    <div>
      <div v-if="product_list.length > 0 || searching != ''" class="d-flex justify-space-around align-center pa-4" style="background-color:#ffffff;height:60px">
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>

        <v-btn icon class="ml-4" color="#d31145">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </div>
      <v-card flat v-if="product_list.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan Produk
          </div>

          <div style="font-weight: normal;font-size: 14px;color:#ACACAC;line-height: 1.5;padding-bottom: 5px">
            Belum ada produk atau layanan pada toko ini. 
            <br>
            Anda bisa menambahkan dengan menekan tombol di bawah.
          </div>

          <div style="padding-top:20px">
            <v-btn to="/master/product/form" rounded depressed color="#d31145" elevation="0" class="white--text text-capitalize">
              <v-icon>mdi-plus</v-icon>
              Tambahkan
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card flat v-else-if="product_list.length < 1 && searching != ''">
        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">
            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto; 
            height: calc(100vh - 120px);
            background: #f9f9f9;
            padding-bottom: 65px !important;">

          <div v-if="product_list.length < 1 && searching == ''">
            <v-row class="py-5" style="padding: 0 15px 0 15px">
              <v-col cols="6" v-for="item in 15" :key="item">
                <v-skeleton-loader
                  class="rounded-lg"
                  type="card, list-item-two-line">
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <v-row class="py-5" style="padding: 0 15px 0 15px">
            <v-col cols="6" v-for="(item, i) in product_list" :key="i">
              <v-card 
                elevation="4" 
                style="background-color: #ffffff" 
                class="rounded-lg pa-3 box-shadow">
                <v-img
                  width="200"
                  height="200"
                  class="rounded-lg mx-auto"
                  :src="item.image_url">
                  <div v-show="item.discount_percent > 0 || item.discount_nominal > 0">
                    <div 
                      v-if="item.discount_type == 'percent'"
                      class="pa-1 d-flex align-center white--text justify-center"
                      style="
                        z-index: 99999;
                        background-color: #d31145; 
                        width: 100px; 
                        height: 25px;
                        position: absolute; 
                        right: 0;">
                      Diskon {{ item.discount_percent }} %
                    </div>
                    <div 
                      v-else
                      class="pa-1 d-flex align-center white--text justify-center"
                      style="
                        z-index: 99999;
                        background-color: #d31145; 
                        width: auto; 
                        height: 25px;
                        position: absolute; 
                        right: 0;">
                      Diskon {{ item.discount_nominal | price }}
                    </div>
                  </div>
                  <div
                    class="pa-1 d-flex align-center white--text justify-space-between"
                    style="
                      z-index: 99999;
                      background-color: #F3F3F3; 
                      width: 100%; 
                      height: 40px;
                      position: absolute; 
                      font-weight: 500;
                      bottom: 0;">
                    <div class="pl-2 text-title" style="font-weight: 500;">
                      {{ item.sku }}
                    </div>
                    <div>
                      <v-btn icon color="#d31145" :to="`/master/product/form?id=${item.id}`">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn style="z-index: 999999" icon color="#d31145" @click="getDetail(item, 'delete')">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        color="#d31145">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <div 
                  @click="getDetail(item)" 
                  class="d-flex justify-space-between align-center cursor-pointer" 
                  style="padding: 10px 0 10px 0; height:80px;">
                  <div class="py-2 ">
                    <div class="text-title" style="font-weight: 500;font-size: 16px"> {{ item.name }} </div>
                    <div class="font-weight-bold text-title"> {{ item.sales_price | price }} </div>
                  </div>
                </div>
                <div style="border: 1px dashed #FA91AD;width: 100%;"></div>
                <div style="padding-top:10px">
                  <span class="text-red">Status</span>
                  <div style="padding-top:10px;padding-bottom: 5px;">
                    <v-chip
                      small
                      :class="item.is_active_bool ? 'white--text' : ''"
                      :color="item.is_active_bool ? '#d31145' : ''">
                      {{ item.is_active_bool == true ? 'Aktif' : 'Tidak Aktif'}}
                    </v-chip>
                  </div>
                  <div>
                    <v-chip
                      small
                      :class="item.is_sale_bool ? 'white--text' : ''"
                      :color="item.is_sale_bool ? '#d31145' : ''">
                      {{ item.is_sale_bool ? 'Tampil di Kasir' : 'Tidak Tampil di Kasir'  }}
                    </v-chip>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </div>

    <v-btn v-if="product_list.length > 0" class="float white--text text-capitalize" rounded color="#d31145" to="/master/product/form">
      <v-icon color="#fff" class="my-float" left>mdi-plus</v-icon>
      Tambahkan
    </v-btn>

    <v-dialog 
      v-model="dialog.detail"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      fullscreen>
      <v-card flat>
        <v-card-title style="height:60px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
          <v-icon @click="dialog.detail = false" color="#d31145">mdi-arrow-left</v-icon>
          <span style="padding-left:15px;font-weight:700;font-size: 20px">Detail Produk</span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="d-flex-column justify-center align-center text-center pa-0" style="height:200px">
          <v-img
            width="150"
            height="150"
            class="rounded-lg mx-auto mt-8 mb-0"
            :src="detail.image_url">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="pt-0 pb-0">
            <div class="body-1 font-weight-bold text-title text-capitalize"> {{ detail.name }} </div>
            <div class="body-1 text-red"> {{ detail.sku }} </div>
          </div>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-text style="height: 250px">
          <table class="pt-8">
            <tbody>
              <tr>
                <th class="text-second pl-0" width="60%">Status Produk</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second">
                  {{ detail.is_active_bool == true ? 'Aktif' : 'Tidak aktif' }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Tampil di Kasir</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second">
                  {{ detail.is_sale_bool == true ? 'Aktif' : 'Tidak aktif' }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Tipe Produk</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second">
                  {{ detail.type }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Satuan</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second">
                  {{ detail.unit }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Minimum Penjualan</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second text-capitalize">
                  {{ detail.sales_minimum }} {{ detail.unit }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Harga Beli</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second font-weight-bold" style="font-size: 18px">
                  {{ detail.purchase_price | price }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Harga Jual</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second font-weight-bold" style="font-size: 18px">
                  {{ detail.sales_price | price }}
                </td>
              </tr>
              <tr>
                <th class="text-second pl-0" width="60%">Diskon</th>
                <td width="10%" class="text-center text-second">:</td>
                <td class="text-start text-second font-weight-bold" style="font-size: 18px">
                  <span v-if="detail.discount_type == 'nominal'">
                    {{ detail.discount_nominal | price }}
                  </span>
                  <span v-if="detail.discount_type == 'percent'">
                    {{ detail.discount_percent | percent }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

        </v-card-text>

        <v-card-text width="100%">
          <table width="100%">
            <thead>
              <tr>
                <th width="25%" class="text-start">Tipe Penjualan</th>
                <th width="25%" class="text-center">Harga Asli</th>
                <th width="25%" class="text-center">Harga</th>
                <th width="25%" class="text-center">Selisih</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in detail.sales_type_markup" :key="i">
                <td width="25%" class="text-start"> {{ item.sales_type }} </td>
                <td width="25%" class="text-center"> {{ item.real_price | price }}</td>
                <td width="25%" class="text-center"> {{ item.markup_price | price }}</td>
                <!-- <td width="25%" class="text-center"> {{ Number(detail.sales_price) + Number(item.markup_value) | price }} </td> -->
                <td width="25%" class="text-center"> {{ item.price_gap | price }} </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Hapus Produk
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin menghapus Produk &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ detail.name }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="deleteData()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, Hapus Data
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  
  </div>
</template>

<script>
export default {
  data: () => ({
    product_list: [],
    sales_type_list: [],
    pagination: {},
    searching: "",
    confirmation: false,
    detail: {},
    process: {
      run: false
    },
    dialog: {
      detail: false,
    }
  }),
  components: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;

      this.initialize();
    }, 500),
  },
  computed: {

  },
  created() {

  },
  mounted(){
    this.initialize()
    this.getSalesType()
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > window.innerHeight-100
    },
    goBack(){
      this.$router.push(`/master`)
    },
    async getSalesType(){
      this.process.run = true
      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 20,
        page: 1
      }
      let res = await this.$get(`pos/sales-type`, params)

      if (res.status == 200) {
        this.process.run = false
        this.sales_type_list = res.results.data
      }

      this.process.run = false
    },
    async initialize(){
      this.process.run = true
      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 1000,
        page: 1
      }
      let res = await this.$get(`pos/product`, params)

      if (res.status == 200) {
        this.process.run = false
        this.product_list = res.results.data
        this.pagination = res.results.pagination
      }

      this.process.run = false
    },
    async getDetail(item, status){
      
      if (status == 'delete') {
        this.detail = item
        this.confirmation = true
      }else{
        let res = await this.$get(`pos/product/detail?id=${item.id}`)

        if (res.status == 200) {
          this.detail = res.results.data
          this.detail.sales_type_markup = this.detail.sales_type_markup.reverse()
          this.dialog.detail = true
        }
      }
    },
    async deleteData(){
      this.confirmation = false
      this.$store.state.overlay.state = true
      this.process.run = true
      let data = {
        id: this.detail.id,
      }
      let res = await this.$post(`pos/product/delete`, data)

      if (res.status == 200) {
        this.process.run = false
        this.initialize()
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
      }

      this.process.run = false
      this.$store.state.overlay.state = false
    },
  },
}
</script>